<template>
  <!-- Prices -->
    <div style="width: 100%;">
        <b-button
            v-if="showAddPriceList || priceLists.length === 0"
            variant="primary" size="xl" @click="addNewPriceList()"
        >
            Agregar nueva lista de precios de venta
        </b-button>
        <br>
        <b-tabs
                horizontal
                content-class="col-12 col-md-12 mt-1 mt-md-0"
                pills
                nav-wrapper-class="col-md-12 col-12"
                nav-class="nav-left"
                v-model="tabIndex"
                @input="selectPriceList()"
        >
            <!-- Client tab -->
            <b-tab v-for="priceList in priceLists" :key="priceList.priceListId">
                <!-- title -->
                <template #title>
                    <feather-icon
                            icon="UserIcon"
                            size="18"
                            class="mr-50"
                    />
                    <span class="font-weight-bold">{{priceList.name}}</span>
                </template>
                <b-overlay :show="tableOverlay" rounded="sm">
                    <b-card>
                        <div class="mt-2" style="text-align: right">
                            <b-button
                                variant="outline-danger"
                                class="mt-0 mt-md-2"
                                @click="removePriceList(priceList.priceListId, tabIndex)"
                            >
                                <feather-icon
                                    icon="XIcon"
                                    class="mr-25"
                                />
                                <span>Eliminar lista de precios</span>
                            </b-button>
                        </div>
                        <b-form-group
                            label="Nombre de la lista"
                            label-for="priceListName"
                        >
                            <b-form-input
                                id="priceListName"
                                v-model="priceList.name"
                            />
                        </b-form-group>
                        <b-form-group
                            label="Clientes que aplican"
                            label-for="companiesId"
                        >
                            <v-select
                                id="companiesId"
                                v-model="priceList.companyIds"
                                label="name"
                                :options="companies"
                                :reduce="option => option.companyId"
                                class="select-size-lg"
                                multiple
                            />
                        </b-form-group>
                        <div class="">
                            <table class="table">
                                <thead>
                                <tr>
                                    <th>Material</th>
                                    <th>Precio de compra</th>
                                    <th>Posision en POS</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(priceListProduct, index) in priceList.priceListProducts" :key="priceListProduct.priceListProductId">
                                    <td>
                                        <v-select
                                            :disabled="!!priceListProduct.priceListProductId"
                                            v-model="priceListProduct.companyProductCatalogId"
                                            label="name"
                                            :options="companyProductsCatalog"
                                            :reduce="option => option.companyProductCatalogId"
                                            class="select-size-m"
                                        />
                                    </td>
                                    <td>
                                        <b-form-input
                                                type="number"
                                                @keypress="restrict"
                                                v-model="priceListProduct.price"
                                                min="0"
                                        />
                                    </td>
                                    <td>
                                        <b-form-input
                                            @keypress="restrict"
                                            v-model="priceListProduct.position"
                                        />
                                    </td>
                                    <td>
                                        <b-button
                                            variant="outline-danger"
                                            class="mt-0 mt-md-2"
                                            @click="removeItem(priceList.priceListProducts, index)"
                                        >
                                            <feather-icon
                                                icon="XIcon"
                                                class="mr-25"
                                            />
                                            <span>Eliminar</span>
                                        </b-button>
                                    </td>
                                </tr>
                                <tr>
                                    <b-button
                                        variant="outline-primary"
                                        @click="addNewItem(priceList.priceListId, priceList.priceListProducts)"
                                    >
                                        <feather-icon
                                            icon="PlusIcon"
                                            class="mr-25"
                                        />
                                        <span>Agregar un item</span>
                                    </b-button>
                                </tr>
                                </tbody>
                            </table>
                            <div v-if="priceLists.length === 0" class="mt-2" style="text-align: center">
                                <h6 class="mb-2">No hay registros.</h6>
                            </div>
                        </div>
                        <div class="d-flex mt-2">
                            <b-button class="mr-2" variant="primary" @click="showModal('sendPriceListViaWhatsApp')">
                                <feather-icon icon="SaveIcon" class="mr-50" />
                                Enviar lista de precios por WhatsApp
                            </b-button>
                            <b-button variant="primary" @click="savePriceList(priceList)">
                                <feather-icon icon="SaveIcon" class="mr-50" />
                                Guardar cambios de {{ priceList.name }}
                            </b-button>
                        </div>

                    </b-card>
                </b-overlay>
            </b-tab>
        </b-tabs>
        <b-modal id="sendPriceListViaWhatsApp"
                 title="¿Esta seguro que desea notificar a todos los clientes?"
                 @ok="sendPriceListViaWhatsAppApi()"
                 ok-title="Si autorizo"
                 cancel-title="Cancelar"
                 size="lg"
                 centered
                 no-close-on-backdrop
                 hide-header-close
        >
            <h2>Esta accion genera costo por cada mensaje enviado.</h2>
        </b-modal>
    </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import {BCard, BFormInput, BButton, BOverlay, BTab, BTabs, BFormGroup, BModal} from 'bootstrap-vue';
import utils from '@/utils/utils.js';
import vSelect from "vue-select";

export default {
  components: {
      BModal,
      BFormGroup,
      BTabs, BTab,
    BCard,
    BFormInput,
    BButton,
    BOverlay,
      vSelect,
  },
  data() {
    return {
      // Data
      user: JSON.parse(localStorage.getItem("user")),
      priceLists: [],
        priceListId: undefined,
      companies: [],
      tabIndex: 0,
      priceListProductIdsToRemove: [],
      companyProductsCatalog: [],

      // Props
      tableOverlay: true,
    showAddPriceList: false,
    };
  },
  created() {
    this.findPriceListsApi();
    this.findCompanyProductsCatalogApi();
  },
  methods: {
      selectPriceList(){
          if (this.tabIndex >= 0){
              const priceListId = this.priceLists[this.tabIndex].priceListId
              this.priceListId = this.priceLists[this.tabIndex].priceListId
              this.findCompanyIdsWithNotPriceListApi(priceListId);
          }
      },
      findCompanyIdsWithNotPriceListApi(priceListId) {
          this.$axios
              .post(this.$services + 'manager/findCompanyIdsWithNotPriceListApi', {
                  companyId: this.user.companyId,
                  priceListId,
                  companyRole: "BUYER"
              })
              .then((res) => {
                  if (res.data) {
                      this.companies = res.data;
                      this.showAddPriceList = true;
                  }
              })
    },
    findPriceListsApi() {
        this.$axios
            .post(this.$services + 'manager/findPriceListsWithProductPriceListApi', {
                    companyId: this.user.companyId,
                    priceListType: 'SELL'
            })
            .then((res) => {
                if (res.data) {
                    this.priceLists = res.data;
                }
            })
            .finally(() => {
                this.tableOverlay = false;
            });
    },
    findCompanyProductsCatalogApi() {
      this.$axios
          .post(this.$services + 'manager/findCompanyProductsCatalogApi', {
              companyId: this.user.companyId,
          })
          .then((res) => {
              if (res.data) {
                  this.companyProductsCatalog = res.data;
              }
          })
    },
    savePriceList(priceList) {
        this.tableOverlay = true;

        if (priceList.priceListId)
          this.changePriceListApi(priceList)
        else
            this.createPriceListApi(priceList)
    },
    createPriceListApi(priceList) {
      this.$axios
          .post(this.$services + 'manager/createPriceListApi', {
              priceListId: priceList.priceListId,
              name: priceList.name,
              companyId: priceList.companyId,
              companyIds: priceList.companyIds,
              priceListProducts: priceList.priceListProducts,
              priceListProductIdsToRemove: this.priceListProductIdsToRemove,
              type: 'SELL'
          })
          .then((res) => {
              this.showToast('success', '¡Lista creada con éxito!');
          })
          .finally(() => {
              this.findPriceListsApi();
              this.tableOverlay = false;
              this.showAddPriceList = true;
          });
    },
    changePriceListApi(priceList) {
      this.$axios
          .post(this.$services + 'manager/changePriceListApi', {
              priceListId: priceList.priceListId,
              name: priceList.name,
              companyId: priceList.companyId,
              companyIds: priceList.companyIds,
              priceListProducts: priceList.priceListProducts,
              priceListProductIdsToRemove: this.priceListProductIdsToRemove
          })
          .then((res) => {
              this.showToast('success', '¡Precios actualizados con éxito!');
          })
          .finally(() => {
              this.findPriceListsApi();
              this.tableOverlay = false;
              this.showAddPriceList = true;
          });
    },
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notificación',
          icon: 'BellIcon',
          text,
          variant,
        },
      });
    },
    restrict() {
      const keyCode = (event.keyCode ? event.keyCode : event.which);
      const stringValue = event.target.value.toString();
      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || stringValue.indexOf('.') != -1)) {
        event.preventDefault();
        return;
      }
    },
    formatterNumber(value) {
      return utils.numberWithCommas(value);
    },
    addNewItem(priceListId, priceListProducts) {
        priceListProducts.push({
            priceListId: priceListId,
            companyProductCatalogId: null,
            price: null,
            position: null,
        });
    },
    addNewPriceList(){
        this.showAddPriceList = false
          this.priceLists.push({
              name: 'Nueva lista',
              companyId: this.user.companyId,
              companyIds: [],
              priceListProducts: []
          })
    },
    removeItem(priceListProducts, index) {
        const priceListProductId = priceListProducts[index].priceListProductId
        if (priceListProductId) {
            this.priceListProductIdsToRemove.push(priceListProductId)
        }
        priceListProducts.splice(index, 1);
    },
    removePriceList(priceListId, index) {
      if (priceListId) {
          this.removePriceListApi(priceListId)
      }
      this.priceLists.splice(index, 1);
    },
    removePriceListApi(priceListId) {
      this.$axios
          .post(this.$services + 'manager/removePriceListApi', {
              priceListId
          })
          .then((res) => {
              this.showToast('success', 'Lista eliminada');
          })
          .finally(() => {
              this.findPriceListsApi();
              this.tableOverlay = false;
      });
    },
    sendPriceListViaWhatsAppApi(){
        this.$axios
            .post(this.$services + 'manager/sendPriceListViaWhatsAppApi', {
                priceListId: this.priceListId,
            })
            .then((res) => {
                this.showToast('success', 'Lista enviada por Whatsapp');
            })
            .finally(() => {
                this.findPriceListsApi();
                this.tableOverlay = false;
            });
    },
      showModal(modalName) {
          this.$bvModal.show(modalName);
      },
      hideModal(modalName) {
          this.$bvModal.hide(modalName);
      },
  },
};
</script>
